import React from 'react';
import styled, { css } from 'styled-components';
import GUESTY_LOGO from '../../../images/product-assets/guesty_logo-colored.svg';
import HOSTAWAY_LOGO from '../../../images/product-assets/hostaway-logo.svg';
import LODGIFY_LOGO from '../../../images/product-assets/lodgify_logo.svg';
import CLOUDBEDS_LOGO from '../../../images/product-assets/cloudbeds_logo.svg';
import { useModal } from '../../../context/ModalContext';

const Wrapper = styled.section`
    padding: 110px 24px 0 24px;
    max-width: 1280px;
    margin: auto;

    @media only screen and (max-width: 850px) {
        padding: 72px 24px 0 24px;
    }

    @media only screen and (max-width: 600px) {
        padding: 54px 14px 0 14px;
    }
`;

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #eff8ff;
    border: 1px solid #b9e6fe;
    border-radius: 24px;
    padding: 36px 81px 36px 36px;
    position: relative;
    overflow: hidden;
    z-index: 2;

    &::before {
        content: '';
        position: absolute;
        height: 323px;
        width: 323px;
        background-color: #d8eefc;
        top: 89px;
        left: 340px;
        border-radius: 100%;
        z-index: -1;
    }

    &::after {
        content: '';
        position: absolute;
        background-color: #d8eefc;
        height: 540px;
        width: 280px;
        top: 0;
        bottom: 0;
        right: -138px;
        transform: skew(40deg);
        z-index: -1;
    }

    @media only screen and (max-width: 850px) {
        padding: 24px 32px;

        &::before {
            height: 198px;
            width: 198px;
            top: 108px;
            left: 100px;
        }

        &::after {
            height: 439px;
            width: 160px;
            right: -80px;
            transform: skew(34deg);
        }
    }

    @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        border-radius: 12px;
        padding: 16px;

        &::before {
            width: 163px;
            height: 163px;
            top: 250px;
            left: 27px;
        }

        &::after {
            width: 140px;
            right: -105px;
        }
    }
    @media only screen and (max-width: 430px) {
        &::before {
            top: 220px;
        }
    }
`;

const LeftBox = styled.div`
    max-width: 466px;

    @media only screen and (max-width: 850px) {
        max-width: 100%;
        padding-right: 32px;
    }

    @media only screen and (max-width: 600px) {
        padding-right: 0px;
    }
`;

const RightBox = styled.div`
    width: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-self: end;
    grid-row: span 2;
    gap: 20px 24px;

    @media only screen and (max-width: 850px) {
        gap: 12px 24px;
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        justify-self: center;
        margin: 24px 0;
    }
`;

const Heading = styled.h1`
    font-size: 32px;
    line-height: 40px;
    color: #1d2939;
    font-weight: 500;

    @media only screen and (max-width: 850px) {
        font-size: 22px;
        line-height: 27.5px;
    }
`;

const SubText = styled.p`
    font-size: 18px;
    line-height: 27px;
    color: #1d2939;
    font-weight: 400;
    padding-top: 12px;

    @media only screen and (max-width: 850px) {
        font-size: 14px;
        line-height: 21px;
        padding-top: 8px;
        max-width: 275px;
    }
`;

const BottomText = styled.p`
    font-size: 14px;
    line-height: 21px;
    color: #1d2939;
    font-weight: 400;
    padding-top: 18px;

    @media only screen and (max-width: 850px) {
        font-size: 12px;
        line-height: 18px;
        max-width: 312px;
    }

    @media only screen and (max-width: 600px) {
        padding-top: 0px;
    }
`;

const Button = styled.button`
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    color: #1570ef;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    background: none;
    border: none;

    @media only screen and (max-width: 850px) {
        font-size: 12px;
    }
`;

const SharedStyles = css`
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5.91px;

    @media only screen and (max-width: 850px) {
        border-radius: 8px;
    }
`;

const SharedImageStyle = css`
    padding: 0px 18px;
    border-radius: 5.95px;
    height: 40px;
    width: 100%;

    @media only screen and (max-width: 850px) {
        height: 32px;
        border-radius: 4.05px;
    }

    @media only screen and (max-width: 600px) {
        padding: 6px 10px;
        height: 87%;
        width: 87%;
    }
`;

const Box = styled.div`
    max-width: 188px;
    ${SharedStyles}
    background-color: ${({ color }) => color || '#fff'};
    padding: ${({ isReleased }) => (!isReleased ? '20px 20px 2px 20px' : '20px')};

    @media only screen and (max-width: 820px) {
        max-width: 152px;
        padding: ${({ isReleased }) => (!isReleased ? '12px 16px 0px 16px' : '12px 16px')};
    }

    @media only screen and (max-width: 600px) {
        max-width: 100%;
        padding: ${({ isReleased }) => (!isReleased ? '10px 10px 2px 10px' : '10px')};
    }
`;

const Image = styled.img`
    background-color: ${({ color }) => color || '#fff'};
    ${SharedImageStyle}
`;

const JoinCta = styled.a`
    font-size: 11px;
    text-transform: uppercase;
    color: ${({ color }) => color || '#fff'};
    background-color: none;
    outline: none;
    border: none;

    @media only screen and (max-width: 600px) {
        font-size: 10px;
    }
`;

const IntegrationBanner = () => {
    const { handlePmsOpen, handleLodgifyFormOpen, handleCloudBedsFormOpen } = useModal();
    return (
        <Wrapper>
            <Container>
                <LeftBox>
                    <Heading>Automated Guest Screenings</Heading>
                    <SubText>
                        Partner up with our PMS partners to fully automate and revolutionize your
                        guest verification experience.
                    </SubText>
                </LeftBox>
                <RightBox>
                    <a
                        href="https://portal.authenticate.com/integrations/guesty"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Box color="#B2DDFF" isReleased={true}>
                            <Image src={GUESTY_LOGO} alt="Guesty Logo" color="#10275B" />
                        </Box>
                    </a>

                    <a
                        href="https://portal.authenticate.com/integrations/hostaway"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Box color="#FFEAD5" isReleased={true}>
                            <Image src={HOSTAWAY_LOGO} alt="Hostaway Logo" color="#FF6A0A" />
                        </Box>
                    </a>

                    <Box isReleased={false} onClick={handleLodgifyFormOpen}>
                        <Image src={LODGIFY_LOGO} alt="Lodgify Logo" color="#FEE4E2" />
                        <JoinCta type="button" color="#B42318">
                            Join Waitlist
                        </JoinCta>
                    </Box>
                    <Box isReleased={false} onClick={handleCloudBedsFormOpen}>
                        <Image src={CLOUDBEDS_LOGO} alt=" cloudbeds logo" color="#1D55CC" />
                        <JoinCta color="#175CD3" type="button">
                            Join Waitlist
                        </JoinCta>
                    </Box>
                </RightBox>
                <BottomText>
                    Don't see your favorite PMS?{' '}
                    <Button type="button" onClick={handlePmsOpen}>
                        Click here
                    </Button>{' '}
                    and we’ll make it happen
                </BottomText>
            </Container>
        </Wrapper>
    );
};

export default IntegrationBanner;
