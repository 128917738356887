import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { VERIFICATION_PROCESS } from '../utilities';
import { useIsomorphicLayoutEffect } from '../../../hooks/useIsomorphicLayoutEffect';

gsap.registerPlugin(ScrollTrigger);

const Wrapper = styled.section`
    margin-top: 110px;
    background-color: #050521;
    padding: 82px 0px;
    height: max-content;
    overflow: hidden;

    @media only screen and (max-width: 1024px) {
        margin-top: 72px;
        padding: 36px 0px;
    }
`;

const Content = styled.div`
    max-width: 1280px;
    margin: auto;
    padding: 0 24px;
`;

const HeadingContainer = styled.div`
    text-align: center;
`;

const Heading = styled.h1`
    color: #fff;
    font-size: 44px;
    font-weight: 500;
    line-height: 57.6px;

    ${({ gradient }) =>
        gradient &&
        css`
            background: linear-gradient(90deg, #ffffff 0%, #91ccff 79%, #0088ff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-style: italic;
        `}

    @media only screen and (max-width: 1024px) {
        font-size: 28px;
        line-height: 33.6px;
    }
`;

const Main = styled.div`
    display: grid;
    grid-template-columns: 0.6fr 1fr;
    margin-top: 54px;

    @media only screen and (max-width: 1024px) {
        grid-template-columns: 0.6fr 1fr;
    }
`;

const LeftBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

const RightBox = styled.div`
    width: 100%;
    position: relative;
    height: auto;
    margin-left: 120px;

    @media only screen and (max-width: 820px) {
        margin-left: 50px;
    }
`;

const TextCard = styled.div`
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border: 1px solid #344054;
    border-radius: 12px;
    opacity: 0.3;
    transition: opacity 0.5s ease;

    &.active {
        opacity: 1;
    }

    @media only screen and (max-width: 820px) {
        padding: 12px;
        gap: 8px;
    }
`;

const Title = styled.h2`
    color: #fff;
    font-size: 26px;
    font-weight: 500;
    line-height: 28.44px;

    @media only screen and (max-width: 820px) {
        font-size: 14px;
        line-height: 16.59px;
    }
`;

const SubText = styled.p`
    color: #fff;
    font-size: 16px;
    line-height: 22.4px;
    font-weight: 400;

    @media only screen and (max-width: 820px) {
        font-size: 12px;
        line-height: 16.9px;
    }
`;

const Icon = styled.img`
    display: none;
    ${({ active }) =>
        active &&
        css`
            display: block;
            background-color: #fff;
            padding: 10px;
            border-radius: 4px;
            border: 1px solid #fff;
            height: 42px;
            width: 42px;
            transition: all 0.5s ease;

            @media only screen and (max-width: 820px) {
                height: 32px;
                width: 32px;
                padding: 6px;
            }
        `}
`;

const Image = styled.img`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: ${({ index, length }) => (index === 0 ? 0 : -(14 / length) * index)}%;
    height: ${({ index }) => (index === 0 ? 95 : 95 - index * 10)}%;
    width: ${({ index }) => (index === 0 ? 95 : 95 - index * 10)}%;
    border-radius: 12px;
    z-index: ${({ index, length }) => length - index};
    object-fit: contain;
    filter: ${({ active }) => (active ? 'none' : 'brightness(0.7) grayscale(0.5)')};
    transition: filter 0.5s ease;

    @media only screen and (max-width: 1024px) {
        left: ${({ index, length }) => (index === 0 ? 0 : -(10 / length) * index)}%;
        height: ${({ index }) => (index === 0 ? 80 : 80 - index * 10)}%;
        width: ${({ index }) => (index === 0 ? 70 : 70 - index * 10)}%;
    }

    @media only screen and (max-width: 820px) {
        top: 50%;
        left: ${({ index, length }) => (index === 0 ? 0 : -(6 / length) * index)}%;
        height: ${({ index }) => (index === 0 ? 80 : 80 - index * 10)}%;
        width: ${({ index }) => (index === 0 ? 80 : 80 - index * 10)}%;
    }
`;

const Verifications = () => {
    const sectionRef = useRef(null);
    const textRef = useRef([]);
    const imageRef = useRef([]);
    const iconRef = useRef([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useIsomorphicLayoutEffect(() => {
        const ctx = gsap.context(() => {
            const isTablet = window.matchMedia('(max-width: 820px)').matches;
            const timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: sectionRef.current,
                    start: 'center center',
                    end: '+=1500',
                    pin: sectionRef.current,
                    scrub: 0.6,
                    pinType: 'fixed',
                    pinSpacing: true,
                    onUpdate: (self) => {
                        const index = Math.round(self.progress * (VERIFICATION_PROCESS.length - 1));
                        setActiveIndex(index);
                    },
                },
            });

            VERIFICATION_PROCESS.forEach((_, index) => {
                let xPosition;
                if (isTablet) {
                    xPosition = index === 0 ? 40 : 55 + index * 10;
                } else {
                    xPosition = index === 0 ? 70 : 85 + index * 10;
                }

                timeline
                    .to(
                        textRef.current[index],
                        {
                            opacity: 1,
                            duration: 0.5,
                        },
                        index * 2
                    )
                    .to(
                        imageRef.current[index],
                        {
                            x: xPosition,
                            opacity: 1,
                            scale: index === 0 ? 1.05 : 1 + index * 0.13,
                            duration: 0.5,
                        },
                        index * 2
                    )
                    .to(
                        iconRef.current[index],
                        {
                            visibility: 'visible', // handles both opacity and visibility
                            duration: 0.5,
                        },
                        index * 2
                    );

                // If not the last index, continue fading out the image, text, and icon
                if (index < VERIFICATION_PROCESS.length - 1) {
                    timeline
                        .to(
                            imageRef.current[index],
                            {
                                opacity: 0,
                                duration: 0.3,
                                ease: 'back.out',
                            },
                            index * 2 + 1
                        )
                        .to(
                            textRef.current[index],
                            {
                                opacity: 0.3,
                                duration: 0.5,
                            },
                            index * 2 + 1
                        )
                        .to(
                            iconRef.current[index],
                            {
                                visibility: 'hidden',
                                duration: 0.5,
                            },
                            index * 2 + 1
                        );
                }
            });
        }, sectionRef);

        return () => ctx.revert();
    }, []);

    return (
        <Wrapper id="verifications" ref={sectionRef}>
            <Content>
                <HeadingContainer>
                    <Heading>No PMS? No Problem!</Heading>
                    <Heading>Perform seamless Guest Verification in</Heading>
                    <Heading gradient>30 seconds!</Heading>
                </HeadingContainer>

                <Main>
                    <LeftBox>
                        {VERIFICATION_PROCESS.map((item, index) => (
                            <TextCard
                                key={item.id}
                                ref={(el) => (textRef.current[index] = el)}
                                className={index === 0 ? 'active' : ''}
                            >
                                <Icon
                                    src={item.icon}
                                    alt={item.title}
                                    ref={(el) => (iconRef.current[index] = el)}
                                    active={index === activeIndex}
                                />
                                <Title>{item.title}</Title>
                                <SubText>{item.subText}</SubText>
                            </TextCard>
                        ))}
                    </LeftBox>
                    <RightBox className="cards">
                        {VERIFICATION_PROCESS.map((item, index) => (
                            <Image
                                src={item.image}
                                alt={item.title}
                                key={item.id}
                                index={index}
                                length={VERIFICATION_PROCESS.length}
                                ref={(el) => (imageRef.current[index] = el)}
                                active={index === activeIndex}
                            />
                        ))}
                    </RightBox>
                </Main>
            </Content>
        </Wrapper>
    );
};

export default Verifications;
