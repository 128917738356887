import React from 'react';
import { Helmet } from 'react-helmet';

function GuestVerificationSEO({ og = { image: '', url_path: '' } }) {
    return (
        <div>
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>
                    Authenticate: Automated Guest Screening for Short-Term Rentals | Fight
                    Chargebacks
                </title>
                <meta
                    name="keywords"
                    content="guest screening, short-term rentals, fight chargebacks, background checks, fraud prevention, rental control, Airbnb-style screening"
                />
                <meta
                    name="description"
                    content="Protect your short-term rental business with Authenticate's automated guest screening. Fight chargebacks, prevent fraud, and maximize revenue with instant background checks and global risk screening."
                />

                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="robots" content="index, follow, archive" />

                <meta
                    property="og:title"
                    content="Authenticate: Automated Guest Screening for Short-Term Rentals"
                />
                <meta
                    property="og:description"
                    content="Protect your rental business with instant background checks, fraud prevention, and chargeback protection. Seamless integration with major PMS partners."
                />
                <meta property="og:image" content={og.image} />
                <meta
                    name="twitter:title"
                    content={'Authenticate: Automated Guest Screening for Short-Term Rentals'}
                />
                <meta
                    name="twitter:description"
                    content={
                        'Protect your rental business with instant background checks, fraud prevention, and chargeback protection. Seamless integration with major PMS partners.'
                    }
                />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="og:url" content={`https://authenticate.com/${og.url_path}`} />

                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_US" />
                <meta
                    property="og:site_name"
                    content="Identity Authentication, ID Verification & Background Checks"
                />

                <link
                    rel="shortcut icon"
                    href={
                        'https://u.jimcdn.com/cms/o/s27d67650be169598/img/favicon.png?t=1498234019'
                    }
                    type="image/x-icon"
                />
                <link rel="canonical" href="https://authenticate.com/"></link>
            </Helmet>
        </div>
    );
}

export default GuestVerificationSEO;
